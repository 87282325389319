import React from 'react';
import { ProductsCarouselV2 } from '@mshops-components-library/products-carousel';

import Wrapper from './wrapper';
import withTracks from '../../adapters/withTracks';

const ProductsCarouselWrapper = Wrapper(withTracks(ProductsCarouselV2));

const EnhancedProductsCarousel = (props) => (
  <ProductsCarouselWrapper {...props} componentType="tabbed_carrousel" />
);

export default EnhancedProductsCarousel;
